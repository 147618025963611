export default function Root() {
    return (
    <div className="App">
      <header className="App-header">
        <h1>Ballroom Sports</h1>
        <p>The website is under construction.</p>
        <p>Coming in June 2023</p>
      </header>
    </div>
    );
}