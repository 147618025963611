import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DetailedProfile from './detailed_profile';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default function Profile(props) {
  let name = props.name
  let media = props.media
  let age = props.age
  let location = props.location
  let dance = props.dance
  let partner_gender = props.partner_gender

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  return (
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea onClick={handleOpen}>
          <CardMedia
            sx={{ height: 345 }}
            image={media}
            title={name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Looking for a new {partner_gender} dance partner to compete in {dance}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Age: {age}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Location: {location}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions>
          <Button size="small">Share</Button>
          <Button onClick={handleOpen} size="small">Learn More</Button>
        </CardActions>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={name}
      >
        <Box sx={style}>
          <DetailedProfile {...props} />
        </Box>
      </Modal>
    </div>
  );
}
