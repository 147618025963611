import * as React from 'react';
import { Button } from '@mui/material';
import Profile from './profile'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Some hardcoded data for testing.
var profiles = [
    {
        name: "Kornelia Wierciszewska",
        age: 13,
        media: "https://scontent-sjc3-1.xx.fbcdn.net/v/t39.30808-6/340164235_767779358385508_6267195821655291182_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=5cd70e&_nc_ohc=Ovcr7tVzv_YAX878sdE&_nc_ht=scontent-sjc3-1.xx&oh=00_AfCAZt9g5fNADFBJi1An-DDGyv89c-Kqz60SuPaCuOeW-w&oe=6442E49F",
        location: "London, UK",
        dance: "10-dance",
        partner_gender: "female"
    },
    {
        name: "Max Appleby",
        age: 21,
        media: "https://scontent-sjc3-1.xx.fbcdn.net/v/t39.30808-6/341157367_600670938789549_1100755029860582115_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=730e14&_nc_ohc=MZrGITb385QAX-rk3Ra&_nc_ht=scontent-sjc3-1.xx&oh=00_AfBoPpftVAc08Wm_-8NvR3sEGp1j2Wy_6h3vwyWPDIXgCw&oe=64453FF0",
        location: "Derby, East Midlands",
        dance: "10-dance",
        partner_gender: "female"
    },
    {
        name: "Jasmine White",
        age: 18,
        media: "https://scontent-sea1-1.xx.fbcdn.net/v/t39.30808-6/342209566_1476694089401026_5327092265379833244_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5cd70e&_nc_ohc=4zU1VMCpkasAX9A3htg&_nc_ht=scontent-sea1-1.xx&oh=00_AfBwOr5dd4kZQyKOkjGFv02ZaqX1CTNIVbfWDstB6gPmEg&oe=6444CD93",
        location: "South Wales, UK",
        dance: "ballroom",
        partner_gender: "male"
    },
    {
        name: "Enrika Jankunaitė",
        age: 19,
        media: "https://scontent-sea1-1.xx.fbcdn.net/v/t39.30808-6/341373899_151217824568076_3277294792760373579_n.jpg?stp=cp6_dst-jpg&_nc_cat=110&ccb=1-7&_nc_sid=5cd70e&_nc_ohc=uAGJblXxFNsAX_3bK5m&_nc_ht=scontent-sea1-1.xx&oh=00_AfDE7zkj5NYTR0mzsR65RvFZk5_bY8xH-HVeW46icBix7A&oe=6444D659",
        location: "Lithuania",
        dance: "10-dance or latin",
        partner_gender: "male"
    }
]



export default function PartnerSearch() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div>
            <Button variant="contained">Create a posting</Button>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Location"
                    onChange={handleChange}
                >
                    <MenuItem value={4}>USA</MenuItem>
                    <MenuItem value={5}>Europe</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Age range</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age range"
                    onChange={handleChange}
                >
                    <MenuItem value={4}>Under 5</MenuItem>
                    <MenuItem value={5}>5-10</MenuItem>
                    <MenuItem value={11}>11-15</MenuItem>
                    <MenuItem value={16}>16-20</MenuItem>
                    <MenuItem value={21}>21-25</MenuItem>
                    <MenuItem value={26}>26-30</MenuItem>
                    <MenuItem value={31}>31-35</MenuItem>
                    <MenuItem value={36}>36-40</MenuItem>
                    <MenuItem value={41}>41-45</MenuItem>
                    <MenuItem value={46}>Above 45</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Height range</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Height range"
                    onChange={handleChange}
                >
                    <MenuItem value={4}>Under 100cm</MenuItem>
                    <MenuItem value={5}>101cm-110cm</MenuItem>
                    <MenuItem value={5}>111cm-120cm</MenuItem>
                    <MenuItem value={11}>121cm-130cm</MenuItem>
                    <MenuItem value={16}>131cm-140cm</MenuItem>
                    <MenuItem value={21}>141cm-150cm</MenuItem>
                    <MenuItem value={26}>151cm-160cm</MenuItem>
                    <MenuItem value={31}>161cm-170cm</MenuItem>
                    <MenuItem value={36}>171cm-180cm</MenuItem>
                    <MenuItem value={41}>181cm-190cm</MenuItem>
                    <MenuItem value={46}>Above 190cm</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Dance</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Dance"
                    onChange={handleChange}
                >
                    <MenuItem value={4}>Ballroom</MenuItem>
                    <MenuItem value={5}>Latin</MenuItem>
                    <MenuItem value={11}>10-dance</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Partner role</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Partner"
                    onChange={handleChange}
                >
                    <MenuItem value={4}>Leader</MenuItem>
                    <MenuItem value={5}>Follower</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Willing to relocate</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Relocate"
                    onChange={handleChange}
                >
                    <MenuItem value={4}>Yes</MenuItem>
                    <MenuItem value={5}>No</MenuItem>
                </Select>
            </FormControl>

            <Box sx={{ flexGrow: 1, padding: 2 }}>
                <Grid container spacing={2}>
                    {profiles.map(item => <Grid><Profile {...item} /></Grid> )}
                </Grid>
            </Box>
        </div>
    );
}