import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function DetailedProfile(props) {
  let name = props.name
  let media = props.media
  let age = props.age
  let location = props.location
  let dance = props.dance
  let partner_gender = props.partner_gender

  return (
    <Card sx={{ maxWidth: 500 }}>
      <CardMedia
        sx={{ height: 500 }}
        image={media}
        title={name}
    />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Looking for a new {partner_gender} dance partner to compete in {dance}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Age: {age}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Location: {location}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
      </CardActions>
    </Card>
  );
}
